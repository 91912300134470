<template>
  <div class="form">
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-input">
            <ValidationProvider
              name="nombres"
              rules="required|min:3"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="fields.first_name"
                placeholder="Nombres *"
                id="first_name"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-input">
            <ValidationProvider
              name="apellidos"
              rules="required|min:3"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="fields.last_name"
                placeholder="Apellidos *"
                id="last_name"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-input">
            <ValidationProvider
              name="correo electrónico"
              rules="required|min:3|email"
              v-slot="{ errors }"
            >
              <input
                type="email"
                v-model="fields.email"
                id="email"
                placeholder="Correo electrónico *"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="ol-12 col-sm-6 col-md-6 col-lg-6 col-input">
            <ValidationProvider
              name="país"
              rules="required|min:3"
              v-slot="{ errors }"
            >
              <v-select
                label="name"
                :options="countries"
                v-model="fields.country"
                :clearable="false"
                placeholder="País de residencia"
              >
                <template #no-options="search">
                  <span class="no-options"
                    >Sin resultados para la búsqueda
                    <strong>{{ search.search }}</strong
                    >.
                  </span>
                </template>
              </v-select>
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-input">
            <ValidationProvider
              name="teléfono"
              rules="required|min:7|regex:\+?1?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$"
              v-slot="{ errors }"
            >
              <input
                type="tel"
                v-model="fields.phone"
                id="phone"
                placeholder="Teléfono *"
                :disabled="!fields.country"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-input">
            <label class="info-date-contact"
              >Puedes seleccionar una fecha y horario determinado según tu
              disponibilidad para atendernos. Por favor indicanos tu respuesta y
              te contactaremos en la fecha y horario requerido. Ten en cuenta
              que la hora que nos indiques será interpretada como hora
              colombiana. (Este campo es opcional)</label
            >
            <input
              type="text"
              v-model="fields.contact_date"
              id="contact_date"
              placeholder="yyyy/mm/dd hh:mm a"
            />
          </div> -->
        </div>

        <div class="form-group terms">
          <div class="form-check">
            <ValidationProvider
              name="política de privacidad"
              :rules="{ required_if: { allowFalse: false } }"
              v-slot="{ errors }"
            >
              <input
                class="form-check-input"
                v-model="fields.terms"
                type="checkbox"
                id="option_legal"
                name="option_legal"
              />
              <label class="form-check-label" for="option_legal"
                >Marcando esta casilla consiento el tratamiento de mis datos
                identificativos solicitados en el formulario por parte de
                Manpower como responsable de tratamiento, con la finalidad de
                recibir información sobre la materia en base a mi propio
                consentimiento, que puedo retirar en el momento que desee. Para
                información más ampliada, puedes consultar la Política de
                Privacidad en este<a
                  v-bind:href="termsData"
                  rel="noopener"
                  target="_blank"
                >
                  enlace</a
                >.</label
              >
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="g-captcha">
              <vue-recaptcha
                ref="recaptcha"
                sitekey="6LeuNtIUAAAAAA5Doi0nZDM_fkLnJicGGipy_dHT"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                :loadRecaptchaScript="true"
              ></vue-recaptcha>
            </div>
            <span v-if="captchaValidate" class="invalid-feedback">{{
              captchaValidate
            }}</span>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="submit-button">
              <button class="btn btn-primary" type="submit">
                Enviar datos
              </button>
              <div class="loading" v-if="loading">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
// Vue Captcha
import { VueRecaptcha } from "vue-recaptcha";

// Vee validate
// import {
//   ValidationObserver,
//   ValidationProvider,
//   extend,
//   localize,
// } from "vee-validate";
// import es from "vee-validate/dist/locale/es.json";
// import * as rules from "vee-validate/dist/rules";

// // install rules and localization
// Object.keys(rules).forEach((rule) => {
//   extend(rule, rules[rule]);
// });
// localize("es", es);

// // Install components globally
// Vue.component("ValidationObserver", ValidationObserver);
// Vue.component("ValidationProvider", ValidationProvider);

// SweetAlert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

// Vue Select
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

export default {
  components: {
    VueRecaptcha,
  },
  data: () => ({
    termsData:
      wpApiSettings.site_url +
      "/wp-content/themes/stfinformationmp/library/files/politicas-de-privacidad.pdf",
    loading: false,
    captchaValidate: null,
    countries: [],
    fields: {
      terms: false,
      vueRecaptchaToken: null,
      contact_date: "",
    },
  }),

  mounted() {
    this.getCountries();

    // console.log(moment.tz("America/Bogota").format("YYYY/MM/DD hh:mm a"));

    /**
     * Contact date
     */
    // var vm = this;
    // jQuery("#contact_date")
    //   .datetimepicker({
    //     minDate: moment.tz("America/Bogota"),
    //     maxDate: new Date(new Date().setDate(new Date().getDate() + 15)),
    //     enabledHours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    //     format: "YYYY/MM/DD hh:mm a",
    //     daysOfWeekDisabled: [0, 6],
    //     defaultDate: false,
    //     showClear: true,
    //     showClose: true,
    //     locale: "es",
    //     timeZone: "America/Bogota",
    //     icons: {
    //       time: "far fa-clock",
    //       date: "far fa-calendar-alt",
    //       up: "fas fa-chevron-up",
    //       down: "fas fa-chevron-down",
    //       previous: "fas fa-chevron-left",
    //       next: "fas fa-chevron-right",
    //       today: "fas fa-crosshairs",
    //       clear: "fas fa-trash",
    //       close: "fas fa-times",
    //     },
    //   })
    //   .on("dp.change", function (e) {
    //     let date = moment(e.date).format("YYYY/MM/DD hh:mm a");
    //     vm.fields.contact_date = date;
    //   });
  },

  watch: {
    "fields.country": function (val, oldVal) {
      this.fields.phone = "+" + val.phonecode;
      this.$refs.observer.reset();
    },
  },

  methods: {
    onSubmit() {
      this.loading = true;

      // Vlidate reCaptcha
      if (!this.fields.vueRecaptchaToken) {
        this.captchaValidate = "El campo reCaptcha es requerido";
        this.loading = false;
        return;
      }

      // Send data
      axios
        .post("/wp-json/api-ss-internacional/v1/register", this.fields, {
          headers: {
            "Content-Type": "application/json",
            "X-WP-Nonce": wpApiSettings.nonce,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.$swal({
              title: "Éxito",
              html: response.data.data,
              icon: "success",
              confirmButtonColor: "#3085d6",
            }).then((result) => {
              window.location.href = wpApiSettings.site_url + "/ss-en-linea";
            });
          } else {
            this.$refs.recaptcha.reset();
            this.$swal({
              title: "Oops!",
              html: response.data.data,
              icon: "warning",
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log("Error");
        });
    },
    loadingState() {
      this.loading = false;
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.fields.vueRecaptchaToken = recaptchaToken;
    },
    onCaptchaExpired: function () {
      //
    },
    getCountries() {
      axios
        .get("/wp-json/api-ss-internacional/v1/countries", {
          headers: {
            "Content-Type": "application/json",
            "X-WP-Nonce": wpApiSettings.nonce,
          },
        })
        .then((res) => {
          this.countries = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

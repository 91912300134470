/**
 * We'll load Lodash, a modern JavaScript utility library delivering modularity, performance & extras.
 * Lodash makes JavaScript easier by taking the hassle out of working with arrays, numbers, objects, strings, etc.
 * Lodash’s modular methods are great for: Iterating variables, Manipulating & testing values, Creating composite functions.
 */
window._ = require("lodash");

/**
 * Solve problems with old browsers with es6 promise polyfill
 */
require("es6-promise").polyfill();

// Load Axios
window.axios = require("axios");

// Vue dependecies
window.Vue = require("vue").default;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Load components and init Vue Js
const files = require.context("./", true, /\.vue$/i);
files
  .keys()
  .map((key) =>
    Vue.component(key.split("/").pop().split(".")[0], files(key).default)
  );

(function () {
  var vm = new Vue({
    el: document.querySelector("#contact"),
  });
})();

// Add other functions with jQuery and Javascript
jQuery(document).ready(function ($) {
  console.log("File successfully loaded!");

  // Default values
  const ajaxUrl = wpApiSettings.ajaxurl;
  const ajaxNonce = wpApiSettings.nonce;

  /**
   * Slider Home
   */
  let swiperHome = new Swiper(".swiper-home", {
    loop: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      "@0.00": {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
      },
      "@0.75": {
        slidesPerView: 2,
        spaceBetween: 15,
        centeredSlides: true,
      },
    },
  });

  /**
   * Slider programs
   */
  var swiper = new Swiper(".swiper-slider-programs", {
    pagination: {
      el: ".swiper-pagination",
    },
    autoplay: {
      delay: 7000,
      disableOnInteraction: true,
    },
  });

  /**
   * Animated Page
   */
  var wow = new WOW({
    boxClass: "wow",
    animateClass: "animated",
    offset: 0,
    mobile: false,
  });
  wow.init();

  /**
   * Resposive main
   */
  $("#icon-mobile").click(function () {
    $("#menu-menu-principal").slideToggle();
  });

  $(".search-icon-main").click(function () {
    $(".search-form").slideToggle();
  });

  $(".menu-item-has-children a").click(function () {
    let parent = $(this).parent();
    let children = $(parent).children(".sub-menu");

    if (!children.hasClass("toggle")) {
      children.hide().addClass("toggle");
    }
    if (children.hasClass("toggle")) {
      children.slideToggle();
    }
  });

  /**
   * Recognition show articles
   */
  $(".recognition-article a.read-more").click(function (e) {
    e.preventDefault();
    let postId = $(this).attr("data-postId");
    if (postId) {
      $.ajax({
        url: ajaxUrl,
        type: "POST",
        dataType: "json",
        data: {
          action: "get_recognition_by_id",
          nonce: ajaxNonce,
          data: postId,
        },
      })
        .done(function (response) {
          let post = `
                    <h3>${response.data.post_title}</h3>
                    <div class="info">
                        ${response.data.post_content}
                    </div>
                `;
          $("#modal-recognition .info-article ").html(post);
          $("#modal-recognition").modal("show");
        })
        .fail(function (response) {
          console.log("error");
        });
    }
  });

  /**
   * On Close modal recognitions
   */
  $("#modal-recognition").on("hidden.bs.modal", function () {
    $(".wp-video-shortcode").trigger("pause");
  });

  /**
   * Open modal Home page
   */
  $("#modal_home").modal("show");

  /**
   * On close modal home
   */
  $("#modal_home").on("hidden.bs.modal", function (e) {
    // Video HTML
    if ($("#video-mp4").length) {
      const video = document.getElementById("video-mp4");
      video.pause();
    }

    // Video Youtube
    if ($("#youtube-player").length) {
      document.querySelectorAll("iframe").forEach((v) => {
        v.src = v.src;
      });
    }
  });

  // Game alphabet soup
  const words = [
    "Amor",
    "Union",
    "Familia",
    "Amistad",
    "Felicidad",
    "Aprendizaje",
    "Comprension",
    "ManpowerGroup",
  ];

  function Init() {
    /**
     * se esta indicando que en el contenedor con el id juego se va a mostrar la sopa de letras y
     * el contenedor con el id palabras va a mostrar las palabras a buscar
     */
    wordfindgame.create(words, "#game", "#words");
    // Estructura de la sopa de letras
    wordfind.newPuzzle(words, {
      height: 25,
      width: 18,
      fillBlanks: false,
    });
  }
  // Load Game
  Init();
});

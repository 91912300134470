<template>
    <div>
        <form autocomplete="off" v-if="!send" action="" method="post" @submit.prevent="submit">
            <p v-if="errors.length">
                <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </p>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label for="first_name">Nombres*</label>
                    <input type="text" class="form-control" id="first_name" v-model="fields.first_name" placeholder="Nombres">
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label for="last_name">Apellidos*</label>
                    <input type="text" class="form-control" id="last_name" v-model="fields.last_name" placeholder="Apellidos">
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label for="email">Correo electrónico*</label>
                    <input type="email" class="form-control" id="email" v-model="fields.email" placeholder="Correo electrónico">
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label for="phone">Teléfono*</label>
                    <input type="text" class="form-control" id="phone" v-model="fields.phone" placeholder="Teléfono">
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="message">Mensaje*</label>
                    <textarea name="message" id="message" v-model="fields.message" cols="30" rows="5"></textarea>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label for="business">Empresa*</label>
                    <select id="business" class="form-control" v-model="fields.business">
                        <option value="">Seleccione...</option>
                        <option value="experis">Experis</option>
                        <option value="manpower">Manpower</option>
                    </select>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="g-captcha">
                        <vue-recaptcha 
                            sitekey="6LeuNtIUAAAAAA5Doi0nZDM_fkLnJicGGipy_dHT" 
                            @verify="onCaptchaVerified"
                            @expired="onCaptchaExpired"
                            :loadRecaptchaScript="true"></vue-recaptcha>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12">
                    <div class="others">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="fields.terms" id="terms">
                            <label class="form-check-label" for="terms">Política de tratamiento de datos personales <a v-bind:href="termsData" target="_blank" rel="noopener noreferrer">Ver aquí</a>  </label>
                        </div>
                        <div class="btn-send">
                            <button type="submit" class="btn btn-primary">Envíar</button>
                            <button type="reset" class="btn btn-secondary">Limpiar</button>
                        </div>
                        <div v-if="loading" class="loading">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div v-if="send" class="send">
            <h3 v-html="message"></h3>
        </div>

    </div>
</template>

<script>
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        components: { 
            VueRecaptcha  
        },
        data: () => ({
            termsData: wpApiSettings.site_url + '/wp-content/themes/stfinformationmp/library/files/politicas-de-privacidad.pdf',
            loading: false,
            send: false,
            message: '',
            fields: {
                business: '',
                vueRecaptchaToken: ''
            },
            errors: []
        }),
        methods: {
            submit( event ){
                this.loading = true;
                let validate = this.validate();
                if( validate ){
                    axios.post('/wp-json/contact/v1/save', this.fields, {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-WP-Nonce': wpApiSettings.nonce
                        }
                    })
                    .then(response => {
                        if( response.data.success ){
                            this.send = true;
                            this.message = response.data.data;
                            this.fields = '';
                        }else{
                            this.errors.push(response.data.data);
                        }
                        this.loadingState();
                    })
                    .catch(error => {
                        this.loadingState();
                        console.log('Error');
                    });
                }else{
                    this.loadingState();
                }
            },
            loadingState(){
                this.loading = false;
            },
            onCaptchaVerified: function (recaptchaToken) {
                this.fields.vueRecaptchaToken = recaptchaToken;
            },
            onCaptchaExpired: function () {
                //
            },
            validate: function ( event ){
                this.errors = [];
                // Validate firts Name 
                if (!this.fields.first_name) {
                    this.errors.push("Los nombres son requeridos.");
                }
                // Validate email
                if (!this.fields.email) {
                    this.errors.push('El correo electrónico es requeridos.');
                } else if (!this.validEmail(this.fields.email)) {
                    this.errors.push('El correo electrónico debe ser válido.');
                }
                // Validate last Name
                if( !this.fields.last_name ){
                    this.errors.push("Los apellidos son requeridos.");
                }
                // Validate phone
                if( !this.fields.phone ){
                    this.errors.push("El teléfono es requerido.");
                }
                // Validate business
                if( !this.fields.business ){
                    this.errors.push('La empresa es requerida.');
                }
                // Validate business
                if( !this.fields.message ){
                    this.errors.push('El campo mensaje es requerido.');
                }
                // Validate Captcha
                if( !this.fields.vueRecaptchaToken ){
                    this.errors.push("El captcha es requerido");
                }
                // Validate terms
                if( !this.fields.terms ){
                    this.errors.push("La política de tratamiento de datos es requerida.");
                }
                
                if (!this.errors.length) {
                    return true;
                }
            },
            validEmail: function (email) {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        },
    }
</script>